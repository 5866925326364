import { useState, useEffect, useLayoutEffect } from 'react'
import { useGLTF } from '@react-three/drei';
import { applyProps } from '@react-three/fiber'
import * as THREE from "three";
import { gsap } from "gsap";

function Car(props) {
  const {
    carId,
    modelFile,
    suspension,
    color,
    selectedPaint,
  } = props;
  const { scene, nodes, materials } = useGLTF(modelFile, '/draco/gltf/')
  const [initBodyNodeY, setInitBodyNodeY] = useState({});

  useEffect(() => {
    nodes['_body'].children.forEach((ch) => {
      if (ch.isMesh) {
        applyProps(ch, { castShadow: true });
      }
    })

    applyProps(nodes['tyre_va_l'], { receiveShadow: true });
    applyProps(nodes['tyre_va_r'], { receiveShadow: true });
    applyProps(nodes['tyre_ha_l'], { receiveShadow: true });
    applyProps(nodes['tyre_ha_r'], { receiveShadow: true });

    Object.keys(materials).forEach((matkey) => {
      if (matkey.includes('Mat_tyres-uv')) {
        applyProps(materials[matkey], { envMapIntensity: 0.5 })
      }
    })

    applyProps(materials["Mat_front-glass"], { ior: 1.1, iridescenceIOR: 1.2, specularIntensity: 1.8, clearcoat: 0.35 })
    if (materials['Mat_rear-lights']) {
      applyProps(materials['Mat_rear-lights'], { emissiveIntensity: 0.25, color:  new THREE.Color('#FF1A05').convertLinearToSRGB() })
    }
    applyProps(materials['Mat_car_glossy'], { color:  new THREE.Color(color).convertLinearToSRGB() })
    if (!initBodyNodeY[carId]) {
      setInitBodyNodeY({
        ...initBodyNodeY,
        [carId]: nodes['_body'].clone().position.y
      })
    } else {
      applyProps(nodes['_body'].position, { y: initBodyNodeY[carId] })
    }
  }, [scene, materials, color]);

  useEffect(() => {
    if (initBodyNodeY[carId]) {
      gsap.to(nodes['_body'].position, { y: initBodyNodeY[carId] + suspension })
    }
  }, [suspension])

  useEffect(() => {
    // 0 = Gloss
    // 1 = Matte
    if (selectedPaint === 0) {
      applyProps(materials['Mat_car_glossy'], { roughness: 0.20000000298023224, clearcoatRoughness: 0.029999999329447746 })
    } else {
      applyProps(materials['Mat_car_glossy'], { roughness: 0.35, clearcoatRoughness: 0.35 })
    }
  }, [selectedPaint, materials])

  return (
    <group>
      <primitive object={scene} />
      <directionalLight
        position={[0, -0.5, 0]}
        intensity={0.5}
        castShadow={false}
      />
      <directionalLight
        position={[0, 2, 0]}
        intensity={0.1}
        castShadow={true}
      />
    </group>
  );
}

export default Car;
